import { render, staticRenderFns } from "./DataCapacityContent.vue?vue&type=template&id=022926b2&scoped=true&"
import script from "./DataCapacityContent.vue?vue&type=script&lang=js&"
export * from "./DataCapacityContent.vue?vue&type=script&lang=js&"
import style0 from "./DataCapacityContent.vue?vue&type=style&index=0&id=022926b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022926b2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git_dev/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('022926b2')) {
      api.createRecord('022926b2', component.options)
    } else {
      api.reload('022926b2', component.options)
    }
    module.hot.accept("./DataCapacityContent.vue?vue&type=template&id=022926b2&scoped=true&", function () {
      api.rerender('022926b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/DataCapacityContent.vue"
export default component.exports