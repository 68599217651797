import { render, staticRenderFns } from "./LinkText.vue?vue&type=template&id=699002a4&"
import script from "./LinkText.vue?vue&type=script&lang=js&"
export * from "./LinkText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git_dev/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('699002a4')) {
      api.createRecord('699002a4', component.options)
    } else {
      api.reload('699002a4', component.options)
    }
    module.hot.accept("./LinkText.vue?vue&type=template&id=699002a4&", function () {
      api.rerender('699002a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/LinkText.vue"
export default component.exports