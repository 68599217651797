var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "failed-login-content mt-5 mb-3 mx-4 login-form-fontFamily"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "row justify-content-center caution-border caution-background py-2 mb-4"
        },
        [
          _c("div", { staticClass: "col-12 d-flex justify-content-center" }, [
            _c(
              "h5",
              { staticClass: "text-flex" },
              [
                _c("IconCaution", { attrs: { width: "30px", height: "30px" } }),
                _c(
                  "font",
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: { color: _vm.colorCautionFont }
                  },
                  [_vm._v(" アカウントが連携されていません。 ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "row justify-content-center info-border info-background py-4 mb-5"
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "col-11 offset-1 mb-2" }, [
            _c(
              "h5",
              { staticStyle: { "font-weight": "normal" } },
              [
                _vm._v(" アカウント情報"),
                _c("IconAccountInfo", {
                  attrs: { height: "3.5rem", width: "3.5rem" }
                }),
                _vm._v("からソーシャルアカウントの連携を行ってください。 ")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-11 offset-1 mb-2" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(" ※別のアカウントで再度ソーシャルログインする場合は、 ")
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                " 一度ログイン中のソーシャルアカウントをログアウトしてください。 "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(" （ログイン中は、ログイン認証画面が表示されません。） ")
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                " ログアウトの方法は各ソーシャルアカウントのサイトをご確認ください。 "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                " アプリ版をお使いの方でソーシャルアカウントをログアウトできない場合は、 "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("a", { attrs: { href: _vm.linkUrl, target: "_blank" } }, [
                _vm._v("こちら")
              ]),
              _vm._v("からお問い合わせください。 ")
            ])
          ]),
          _c("div", { staticClass: "col-11 offset-1 mb-1" }, [
            _c(
              "span",
              [
                _c("IconCautionGray", {
                  attrs: { width: "1rem", "color-style": "#ffffff" }
                }),
                _vm._v(" Apple IDをご利用のお客様へ")
              ],
              1
            )
          ]),
          _vm._m(1),
          _c("div", { staticClass: "col-11 offset-1 mt-3 mb-1" }, [
            _c(
              "span",
              [
                _c("IconCautionGray", {
                  attrs: { width: "1rem", "color-style": "#ffffff" }
                }),
                _vm._v(" Microsoftアカウントをご利用のお客様へ ")
              ],
              1
            )
          ]),
          _vm._m(2)
        ]
      ),
      _c("div", { attrs: { id: "button_area" } }, [
        _c(
          "span",
          { staticClass: "btn-white" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Login" } } },
              [
                _c("ButtonBorderCircleMedium", {
                  attrs: {
                    "label-name": _vm.$t("buttons.returnToLogin"),
                    "border-color": "#2D7CB9",
                    "border-size": "0.2rem",
                    width: "100%"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "span",
          { staticClass: "btn-white" },
          [
            _c("ButtonBorderCircleMedium", {
              attrs: {
                "label-name": _vm.$t("buttons.jumpToSukenMypage"),
                "border-color": "#2D7CB9",
                "border-size": "0.2rem",
                width: "100%"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.onClickJumpToSukenMypage.apply(null, arguments)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-11 offset-1" }, [
      _c("h5", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v(" 数研アカウントのID・パスワードでマイページにログイン後、 ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-10 apple-background px-4 py-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", [
          _vm._v(
            "  Apple IDでソーシャルアカウントの連携を行うには、Apple IDのログイン認証後に "
          )
        ])
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("span", [
          _vm._v(
            "  表示されるメールアドレス設定画面にて「メールを共有」を選択してください。 "
          )
        ])
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("span", [
          _vm._v(
            "  （「メールを非公開」を選択すると、ソーシャルアカウントの連携をご利用いただけません。） "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-10 apple-background px-4 py-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", [
          _vm._v(
            "  Microsoftアカウントでソーシャルアカウントの連携を行うには、 "
          )
        ])
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("span", [
          _vm._v(
            "  Microsoftアカウントのアカウント情報にメールアドレスを必ず設定してください。 "
          )
        ])
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("span", [
          _vm._v(
            "  （メールアドレスの設定がないアカウントでは、ソーシャルアカウントの連携をご利用いただけません。） "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }