import { render, staticRenderFns } from "./BadRequestContent.vue?vue&type=template&id=1506db21&scoped=true&"
import script from "./BadRequestContent.vue?vue&type=script&lang=js&"
export * from "./BadRequestContent.vue?vue&type=script&lang=js&"
import style0 from "./BadRequestContent.vue?vue&type=style&index=0&id=1506db21&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1506db21",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git_dev/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1506db21')) {
      api.createRecord('1506db21', component.options)
    } else {
      api.reload('1506db21', component.options)
    }
    module.hot.accept("./BadRequestContent.vue?vue&type=template&id=1506db21&scoped=true&", function () {
      api.rerender('1506db21', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/BadRequestContent.vue"
export default component.exports