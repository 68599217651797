import { render, staticRenderFns } from "./IconExport.vue?vue&type=template&id=39bf2b7f&scoped=true&"
import script from "./IconExport.vue?vue&type=script&lang=js&"
export * from "./IconExport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39bf2b7f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git_dev/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39bf2b7f')) {
      api.createRecord('39bf2b7f', component.options)
    } else {
      api.reload('39bf2b7f', component.options)
    }
    module.hot.accept("./IconExport.vue?vue&type=template&id=39bf2b7f&scoped=true&", function () {
      api.rerender('39bf2b7f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/icons/IconExport.vue"
export default component.exports