import { render, staticRenderFns } from "./BadRequest.vue?vue&type=template&id=94c8a338&scoped=true&"
import script from "./BadRequest.vue?vue&type=script&lang=js&"
export * from "./BadRequest.vue?vue&type=script&lang=js&"
import style0 from "./BadRequest.vue?vue&type=style&index=0&id=94c8a338&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94c8a338",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git_dev/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('94c8a338')) {
      api.createRecord('94c8a338', component.options)
    } else {
      api.reload('94c8a338', component.options)
    }
    module.hot.accept("./BadRequest.vue?vue&type=template&id=94c8a338&scoped=true&", function () {
      api.rerender('94c8a338', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/errors/BadRequest.vue"
export default component.exports